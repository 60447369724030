<template>
  <v-app>
    <v-main>
      <v-app-bar prominent flat light color="transparent">     
        <v-spacer></v-spacer>  
           <v-toolbar-title>
            <router-link  to="/" class="page-title">{{title}}</router-link>
           </v-toolbar-title>
          <v-spacer></v-spacer>
      </v-app-bar>
      <v-container>
      <router-view :lang="lang" :api="api" :nodes="nodes" :categories="categories"></router-view>          
      <footer>
        <div id="contact">
            <div v-if="lang['value'] === 'et'">Info ja tellimine   mobiil: {{mobile}}   email: {{email}}</div>
            <div v-if="lang['value'] === 'en'">Info and ordering   mobile: {{mobile}}   email: {{email}}</div>
            <div v-if="lang['value'] === 'ru'">Информация и заказ  мобильный: {{mobile}} письмо: {{email}}</div>
            <div id="langs">
             <v-select       
              :items="langs"
              v-model="lang"
              prepend-icon="language"
              return-object>
               <template v-slot:item="{ item }">
                  <img class="flag" :src="item.image"> 
                  </template>
              </v-select>
            </div>
        </div>

    </footer>
    
     </v-container>
    </v-main>
  </v-app>
</template>

<script>

import Vue from 'vue'
import VueRouter from 'vue-router'

import Frontend from './components/Frontend'
import Category from './components/Category'
import Product from './components/Product'

const router = new VueRouter({	
		mode: ('hash'),
		routes:[
      {
        path: '/',
        name: 'frontpage',
        component: Frontend
      },
      {
        path: '/:lang',
        name: 'frontpageLang',
        component: Frontend
      },
      {
        path: '/:lang/:category',
        name: 'category',
        component: Category
      },
      {
        path: '/:lang/:category/:product',
        name: 'product',
        component: Product
      }
    ]
})

Vue.use(VueRouter)

export default {
  name: 'App',
  router: router,
  data: () => ({
    title:"Jaani Sepikoda",
    email:"jaan59021@gmail.com",
    mobile:"55629571",
    api: "https://www.dev.sepised.com",
    lang:{value:"et",text:"eesti"},
    langs:[
      {value:"et",text:"Eesti",image:"/assets/ee.png"},
      {value:"en",text:"English",image:"/assets/en.png"},
      {value:"ru",text:"Rусский",image:"/assets/ru.png"}
    ],
    categories: [] ,
    nodes: [] ,
    options: {mode: 'cors', headers: {'Access-Control-Allow-Origin':'*'}} 
  }),
  watch:{
    '$route.params': function(to){
        if(to.lang != undefined){
          this.lang = this.langs.find(l=>l.value === to.lang)
        }
    }
  },
  created(){

    fetch(`${this.api}/jsonapi/node/sepis?include=field_photo,field_tag,field_detail`,this.options)
      .then(response => response.json())
      .then((data) => {
          this.processData(data)
    });
},
methods:{
  processData(data){

     data.included.filter(d=> d.type == "taxonomy_term--tags").map(d => {
          var map ={}
          map.nodes = []
          map.id = d.id
          map.title = d.attributes.name
          map.et = d.attributes.name
          map.ru = d.attributes.field_name_ru
          map.en = d.attributes.field_term_en
          if(this.categories.filter(c => c.id == map.id).length == 0) {
            this.categories.push(map)
          }         
          return map;
      })

      let filemap = {}
      data.included.filter(d=> d.type == "file--file").map(d => {
        filemap[d.id] = d;
      })

      data.data.map(n=>{
          var nmap ={}
          nmap.id = n.id
          nmap.category = n.relationships.field_tag.data.id
          nmap.photo = n.relationships.field_photo.data.id
          nmap.ru = n.attributes.field_name_ru
          nmap.en = n.attributes.field_name_en
          nmap.title = n.attributes.title
          nmap.et = n.attributes.title
          nmap.promote = n.attributes.promote
          nmap.imagename = filemap[nmap.photo].attributes.filename         

          nmap.details = n.relationships.field_detail.data.map(d=> {
            return filemap[d.id].attributes.filename     
            
          })
          this.nodes.push(nmap)
          return nmap;
      });

      if(data.links != undefined && data.links.next != undefined){
          console.log(data.links.next.href)

          fetch(data.links.next.href,this.options)
            .then(response => response.json())
            .then((next) => {
            this.processData(next)
          });

      }
  }
}

};
</script>
<style scoped>

@font-face {  
	font-family: goudy ;  
	src: url(./assets/goudy.eot);  
}  

@font-face {  
	font-family: goudy ;  
	src: url(./assets/goudy.ttf ) format("truetype");  
}  

@font-face {  
	font-family: arkhip ;  
	src: url(./assets/Arkhip_font.ttf) format("truetype");  
} 

#app{
	background-color: #000;
	color: #fff;
	position:relative;
	background-image: url("./assets/base/css/tuli2.jpg");
	background-position: center bottom;
	background-repeat: no-repeat;
	background-attachment:fixed;

}

footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 30px;
    padding-bottom: 8px;
    text-align: center;
    font-size: 18px;
    color: #FDDE7C;
    background:rgba(10,10,10,0.5)
}

.page-title, .page-title:hover {
    text-align: center;
    text-decoration: none;
    font-family: goudy , georgia, verdana, helvetica, sans-serif;
    font-size: 50px;
    color: #FDDE7C;
}

#contact{
  width: 800px;
  margin: auto;
}

#langs{
  position:relative;
  top:-42px;
  left: 100%;
  width: 140px;
}

</style>>
